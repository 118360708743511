// import todopage           from "@/views/public/general/todopage";
import Login              from "@/views/public/general/login";
// import PasswordReset      from "@/views/public/general/passwordreset";
// import VerifyEmail        from "@/views/public/general/verifyemail";
// import Contact            from "@/views/public/general/contact";
// import Privacy            from "@/views/public/general/privacy";
// import TermsAndConditions from "@/views/public/general/termsandconditions";
// import Disclaimer         from "@/views/public/general/disclaimer";
// import Home               from "@/views/public/home";
// import Test               from "@/views/public/test";
const Video             = () => import('@/views/public/video')
const VideoEditor       = () => import('@/views/superadmin/video_editor')


 const Routes_Public = [{
                              path: "/",
                              name: "home",
                              component: Login,
                              meta: { publicNav: false, hideFooter : true }
                            },{
                              path: "/login",
                              name: "Login",
                              component: Login,
                              props: true,
                              meta: { publicNav: false, hideFooter : true }
                            },{
                              path: "/Video",
                              name: "Video",
                              component: Video,
                              props: true,
                              meta: { publicNav: false, hideFooter : true }
                            },{
                              path: "/Video/:videourl",
                              name: "VideoWithUrl",
                              component: Video,
                              props: true,
                              meta: { publicNav: false, hideFooter : true }
                            },{
                              path: "/IVS-VideoEditor",
                              name: "VideoEditor",
                              component: VideoEditor,
                              props: true,
                              meta: { publicNav: false, hideFooter : true }
                            }
                            // ,{
                            //   path: "/register",
                            //   name: "register",
                            //   component: Login,
                            //   props: { pageViewMode: 'register' },
                            //   meta: { publicNav: false, hideFooter : true }
                            // },{
                            //   path: "/.well-known/change-password",
                            //   name: "change-password",
                            //   component: Login,
                            //   props: { pageViewMode: 'pw_reset' },
                            //   meta: { publicNav: false, hideFooter : true }
                            // },{
                            //   path: "/password-reset",
                            //   name: "passwordreset",
                            //   component: PasswordReset,
                            //   props: true,
                            //   meta: { publicNav: false, hideFooter : true }
                            // },{
                            //   path: "/verifyemail",
                            //   name: "verifyemail",
                            //   component: VerifyEmail,
                            //   props: true,
                            //   meta: { publicNav: false, hideFooter : true }
                            // },{
                            //   path: "/contact",
                            //   name: "Contact",
                            //   component: Contact,
                            //   props: true,
                            //   meta: { publicNav: true }
                            // },{
                            //   path: "/privacy",
                            //   name: "Privacy",
                            //   component: Privacy,
                            //   props: true,
                            //   meta: { publicNav: true }
                            // },{
                            //   path: "/termsandconditions",
                            //   name: "TermsAndConditions",
                            //   component: TermsAndConditions,
                            //   props: true,
                            //   meta: { publicNav: true }
                            // },{
                            //   path: "/disclaimer",
                            //   name: "Disclaimer",
                            //   component: Disclaimer,
                            //   props: true,
                            //   meta: { publicNav: true }
                            // ,{
                            //   path: "/test",
                            //   name: "Test",
                            //   component: Test,
                            //   props: true,
                            //   meta: { publicNav: true }
                            // }
                              
                              
                              
                            // },{
                            //   path: "/home",
                            //   name: "Home",
                            //   component: todopage,
                            //   props: true,
                            //   meta: { publicNav: true }
                            // },{
                            //   path: "/app",
                            //   name: "GetApp",
                            //   component: todopage,
                            //   props: true,
                            //   meta: { publicNav: true }
                            // },{
                            //   path: "/aboutus",
                            //   name: "Aboutus",
                            //   component: todopage,
                            //   props: true,
                            //   meta: { publicNav: true }
                            
                            // }
];

export default Routes_Public;